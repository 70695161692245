@import '../node_modules/bootstrap/dist/css/bootstrap.css';
@import '../node_modules/@fortawesome/fontawesome-free/css/all.css';
@import '../node_modules/@fortawesome/fontawesome-pro/css/all.css';
@import './custom/override-variables.scss';
@import "~@progress/kendo-theme-default/scss/all";
@import './assets/pages/styles/index.scss';

@import './custom/styles.scss';

@page { size: auto }


