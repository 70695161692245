.modal-open {
    .cdk-overlay-container{
        z-index: 1055;
    }
    pg-message-container{
        display: none;
    }
}
.cdk-overlay-container{
    pointer-events: none;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 700;
}

.cdk-overlay-backdrop, .cdk-overlay-pane {
    position: absolute;
    pointer-events: auto;
    z-index: 700;
}

.cdk-overlay-backdrop {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-tap-highlight-color: transparent;
    -webkit-transition: opacity .4s cubic-bezier(.25,.8,.25,1);
    transition: opacity .4s cubic-bezier(.25,.8,.25,1);
    opacity: 0;
}

.cdk-overlay-backdrop, .cdk-overlay-pane {
    position: absolute;
    pointer-events: auto;
    z-index: 700;
}