.page-item{
    .page-link{
        font-size: 12px;
        padding:10px;
        border:none;
        background:transparent;
        color:$color-master;
        opacity: .35;
        border-radius: 3px;
        cursor: pointer;
        &:hover{
            opacity: 0.8;
        }
        
    }
    &.active{
        .page-link{
            opacity: 1;
            background: transparent;
            font-weight: bold;
            color:$color-master;
        }
    }
}
