/* Main Menu Sidebar
------------------------------------
*/

select2.full-width .select2-container {
    width: 100% !important;
}

.container-fluid h4 {
    margin-left: 20px;
}

.form-group-default-select {
    /* padding-bottom: 4px; */
}

a.disabled {
    pointer-events: none;
    cursor: default;
}

.form-horizontal-condensed .form-group {
    border: none !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-bottom: 5px !important;
    padding-top: 5px !important;
}

.form-horizontal-condensed .row [class*='col-']:first-child {
    padding-left: 0;
}

.form-horizontal-condensed .row [class*='col-']:not(:first-child), .form-horizontal-condensed .row [class*='col-']:not(:last-child) {
    padding-right: 7px;
    padding-left: 7px;
}

.form-horizontal-condensed .row [class*='col-']:last-child {
    padding-right: 0;
}

.form-horizontal-condensed h5 {
    margin-top: 25px;
}

/*end form-control from bootstrap*/

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    color: #2c2c2c;
}

/*angular 2+ stuff*/
a {
    cursor: pointer;
}

.form-text {
    background-color: #fff;
    background-image: none;
    border: none;
    font-family: Arial, sans-serif;
    -webkit-appearance: none;
    color: #2c2c2c;
    outline: 0;
    height: 35px;
    padding: 9px 12px;
    line-height: normal;
    font-size: 14px;
    font-weight: normal;
    vertical-align: middle;
    min-height: 35px;
    -webkit-transition: all 0.12s ease;
    transition: all 0.12s ease;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-transition: background 0.2s linear 0s;
    transition: background 0.2s linear 0s;
}

.form-horizontal-condensed .has-error .form-control {
    background-color: rgba(245, 87, 83, 0.1);
    border: 1px solid rgba(0, 0, 0, .07);
}

.control-info {
    color: #48b0f7 !important;
    padding-top: 8px;
}

.ms-grid-container .dx-datagrid-content tr.dx-data-row {
    cursor: pointer !important;
}

.ms-grid-container .dx-datagrid-content tr.dx-data-row .ms-dxi-cell-subproduct {
    padding-left: 40px;
}

/*modal*/
@media (min-width: 1292px) {
    .modal-xlg {
        width: 1200px;
    }
}

input[type=search]::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
}

.form-legend {
    color: #E46159;
    padding: 15px;
}

.float-right{
    float: right;
}

.m-t-n25{
    margin-top: -25px;
}

.datepicker-filter{
    display: inline-block;
    height: 30px !important;
    min-height: 30px !important;
    width: 174px;
}

.form-text .list-group-item {
  margin-top: -10px;
}
